$bg_color: white;
$normal_font_size: 13px;
$grey_transparent_color: rgba(0, 0, 0, 0.5);

html {
  font-size: 14px;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  color: black;
  font-family: "Montserrat";
  background-color: white;
}

.layout {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  scrollbar-color: #9e9fa0 transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9e9fa0;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #9e9fa0;
  }
}

.main {
  min-height: calc(100vh - 200px);
  padding: 50px 90px;
  @media screen and (max-width: 992px) {
    padding: 40px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 30px;
  }
  @media screen and (max-width: 554px) {
    padding: 30px 10px;
  }
}

.btn {
  border-radius: 0px !important;
  font-weight: 500;
  font-size: $normal_font_size;
  line-height: 28px;
}

.default-btn-size {
  min-width: 136px;
  outline: none;
}
.btn-outline-primary:hover {
  background-color: transparent;
  border-color: transparent;
}
.btn-primary:hover {
  background-color: transparent;
  border-color: transparent;
}

.fill-btn {
  position: relative;
  z-index: 1;
  padding: 0;
  &:before {
    transition: 0.5s;
    position: absolute;
    z-index: 0;
    content: '';
    width: calc(100% - -6px);
    height: calc(100% - -6px);
    top: -6px;
    left: -6px;
    background: rgba(20, 20, 20, 0.85);
  }
  &:after {
    transition: 0.5s;
    position: absolute;
    z-index: 0;
    content: '';
    width: calc(100% - -6px);
    height: calc(100% - -6px);
    bottom: -6px;
    right: -6px;
    background: rgba(20, 20, 20, 0.85);
  }
  span {
    font-weight: bold;
    font-size: 11px !important;
    font-family: "Matrice";
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #000000;
    background: #ffffff;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
    white-space: nowrap;
    padding: 12.5px;
    border: none;
  }
  &:hover {
    &:before {
      transform: translate(0px, 6px);
    }
    &:after {
      transform: translate(0px, -6px);
    }
  }
  &:active {
    &:before {
      transform: translate(6px, 6px);
      width: calc(100% - 0px);
      height: calc(100% - 0px);
    }
    &:after {
      transform: translate(-6px, -6px);
      width: calc(100% - 0px);
      height: calc(100% - 0px);
    }
    span {
      color: #FFFBF8;
      background: rgba(20, 20, 20, 0.85);
    }
  }
}

.btn-link {
  padding: 0;
  box-shadow: none;
  border: 0;
  background: transparent;
  color: #000;
  white-space: nowrap;

  &:hover, &:focus, &:active {
    color: #000;
  }

  &:hover {
    > span {
      text-decoration: underline;
    }
  }
}

.outline-btn {
  border-radius: 2px !important;
  color: white;
  border: solid 3px transparent;
  background: rgba(20, 20, 20, 0.85);
  text-transform: uppercase;
  padding: 0;
  box-shadow: none;

  > span {
    background: white;
    font-weight: bold;
    font-size: 11px !important;
    font-family: "Matrice" !important;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: black;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
    white-space: nowrap;
    padding: 12.5px;
    border: none;
  }

  &:hover,
  &:focus {
    background: linear-gradient(135.63deg, rgba(254, 176, 254, 0.2) -0.59%, rgba(34, 39, 39, 0.096) 59.4%, rgba(93, 247, 164, 0.2) 108.48%), rgba(20, 20, 20, 0.85);

    > span {
      color: white;
      background: transparent;
    }
  }

  &:active {
    background: linear-gradient(135.63deg, rgba(254, 176, 254, 0.2) -0.59%, rgba(34, 39, 39, 0.096) 59.4%, rgba(93, 247, 164, 0.2) 108.48%), rgba(20, 20, 20, 0.85)!important;
    box-shadow: none!important;
    border-color: transparent!important;

    > span {
      color: white;
      background: transparent;
    }
  }
}

.light-btn {
  border: 0;
  box-shadow: none;
  outline: none;
  background: #fff;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
  border-radius: 2px!important;

  > span {
    background: white;
    font-weight: bold;
    font-size: 11px !important;
    font-family: "Matrice" !important;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: black;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
    white-space: nowrap;
    padding: 12.5px;
    border: none;
  }

  &:hover,
  &:focus {
    background: linear-gradient(135.63deg, rgba(254, 176, 254, 0.2) -0.59%, rgba(34, 39, 39, 0.096) 59.4%, rgba(93, 247, 164, 0.2) 108.48%), rgba(20, 20, 20, 0.85);

   > span {
      color: white;
      background: transparent;
    }
  }

  &:active {
    background: linear-gradient(135.63deg, rgba(254, 176, 254, 0.2) -0.59%, rgba(34, 39, 39, 0.096) 59.4%, rgba(93, 247, 164, 0.2) 108.48%), rgba(20, 20, 20, 0.85)!important;
    box-shadow: none!important;
    border: 0!important;

   > span {
      color: white;
      background: transparent;
    }
  }
}

.btn-round {
  border-radius: 42px !important;
  span {
    border-radius: 42px;
  }
}

.dropdown-divider {
	border-top: 1px solid #0000002b;
}

.full-width {
  width: 100%;
}

/* color */
.grey-color {
  color: rgb(128, 128, 128) !important;
}

.black-color {
  color: #000000!important;
}

.faint-black-color {
  background: linear-gradient(90deg, #000, #000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-color: black;
}

.faint-color {
  background: linear-gradient(90deg, #000, #000, #000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-color: black;
}

.trans-faint-color {
  color: rgba(0, 0, 0, 0.6) !important;
}

.danger-color {
  color: $danger;
}

.primary-color {
  color: $primary !important;
}

/* cursor */
.pointer-cursor {
  cursor: pointer !important;
}

/* Position */
.pos-absolute {
  position: absolute !important;
}
.pos-relative {
  position: relative !important;
}

/* Margin */
.mr-6 {
  margin-right: 4rem !important;
}
.mr-7 {
  margin-right: 5rem !important;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden !important;
}

/* Opacity */
.o-3 {
  opacity: 0.3!important;
}
.o-5 {
  opacity: 0.5!important;
}

/*Float */
.clear-both {
  clear: both;
}

/* Override Dialog Style */
.modal-dialog {
  .modal-content {
    // backdrop-filter: blur(60px);
    // background: linear-gradient(
    //     135.63deg,
    //     rgba(254, 176, 254, 0.084) -0.59%,
    //     rgba(34, 39, 39, 0.096) 59.4%,
    //     rgba(93, 247, 164, 0.14) 108.48%
    //   ),
    //   rgba(20, 20, 20, 0.85);
    border-radius: 20px;
    .modal-body {
      padding: 20px;
    }
  }
}

.modal-header {
	border-bottom: 1px solid #ffffff1c;
}

.modal-backdrop.show {
	opacity: 1 !important;
}

.modal-backdrop {
	backdrop-filter: saturate(180%) blur(20px);
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	background: rgba(255, 255, 255, 0.7);
	z-index: 1050;
}
/* Overrride Some Package Styles */
.bn-onboard-modal {
  z-index: 1000 !important;
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Matrice";
  src: url(../assets/fonts/Matrice-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Matrice-Regular";
  src: url(../assets/fonts/Matrice-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: url(../assets/fonts/Helvetica.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Bold";
  src: url(../assets/fonts/Helvetica-Bold.ttf) format("truetype");
}

.font-matrice {
  font-family: "Matrice" !important;
}

.dropdown.show {
  .dropdown-toggle::after {
    transform: rotate(90deg) scale(1.3);
  }
}

.dropdown-toggle::after {
  display: inline-block;
  content: "‹";
  margin-left: 0.555em;
  vertical-align: middle;
  transform: rotate(270deg) scale(1.3);
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  transition: transform ease-in .3s;
}

.flex-reserve {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap-reverse;
  }
}

.form-control {
  border-width: 2px;
  background-color: transparent;
  color: black;
  &:focus {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.dropdown-menu {
  background-color: white;
  border-radius: 8px;
  color: black;
  display: block;
  overflow: hidden;
  visibility: hidden;
  transform: scale(0.2);
  opacity: 0;
  &.show {
    opacity: 1;
    transition: all .2s;
    visibility: visible;
    transform: scale(1);
  }
  .dropdown-item {
    color: black;
    &:hover {
      background-color: #e9ecef1a;
    }
    div {
      line-height: 2 !important;
    }
  }
}

a:hover {
  text-decoration: none;
}

.btn:focus, .btn:active {
  border-color: transparent;
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label:before {
  background: #000;
  border-color: transparent
}

.loading-bar span {
  border: solid 5px transparent;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #1AC3F0, #ED489C, #FFCA3D) !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px white inset;
}

.btn-black {
  text-transform: uppercase;
  color: white;
  background-color: black;
  padding: 13.5px;
  line-height: 19px;
  font-weight: bold;
  border: 2px solid black;
  outline: none;
  font-family: "Matrice";
  font-size: 11px;
  &:hover, &:active, &:focus {
    background: linear-gradient(135.63deg, rgba(254, 176, 254, 0.2) -0.59%, rgba(34, 39, 39, 0.096) 59.4%, rgba(93, 247, 164, 0.2) 108.48%), rgba(20, 20, 20, 0.85) !important;
    color: white;
  }
}

.btn-white {
  text-transform: uppercase;
  color: black;
  background-color: white;
  padding: 13.5px;
  line-height: 19px;
  font-weight: bold;
  border: 2px solid #fff;
  outline: none;
  font-family: "Matrice";
  font-size: 11px;
  &:hover, &:active, &:focus {
    background: linear-gradient(135.63deg, rgba(254, 176, 254, 0.2) -0.59%, rgba(34, 39, 39, 0.096) 59.4%, rgba(93, 247, 164, 0.2) 108.48%), rgba(20, 20, 20, 0.85) !important;
    color: white;
  }
}

.dropdown.show > .btn-primary.dropdown-toggle {
  color: black;
}

.font-size-14 {
  font-size: 14px !important;
}

.site-updating {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
  height: 100%;

  .logo {
    width: 122px;
  }

  div {
    color: #fff;
    text-align: center;
    line-height: 2;
    margin-top: 20px;
  }

  a {
    color: #fff;
  }
}