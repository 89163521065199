.header-container {
  height: 89px;
  min-width: 100%;
  box-sizing: border-box;
  background: #000;
  color: white;
  box-shadow: 0 7px 29px 0 rgba(0, 0, 0, 0.07);
  position: sticky;
  top: 0;
  z-index: 999;
  .navbar {
    margin: 0 60px;
    @media screen and (max-width: 1400px) {
      margin: 0 25px;
    }
    @media screen and (max-width: 1200px) {
      margin: 0 0;
    }
  }
  .navbar-brand {
    padding-top: 0;
  }
  .logo {
    height: 25px;
    @media screen and (max-width: 856px) {
      height: 20px;
    }
  }
  @media screen and (max-width: 856px) {
    padding-top: 15px;
  }
  .navbar-nav {
    padding: 15px 30px;
    @media screen and (max-width: 1200px) {
      padding: 15px 20px;
    }
    @media screen and (max-width: 768px) {
      padding: 15px 10px;
    }
  }
  .nav-link {
    color: rgba(white, 0.5) !important;
    font-size:  $normal_font_size;
    white-space: nowrap;
    &.active {
      font-weight: 900;
      color: white!important;  
    }
  }
  #header-nav-create-dropdown:after {
    display: none;
  }
  .header-search-form {
    background: rgba(white, .05);
    // border: 1px solid #ededed;
    box-sizing: border-box;
    // border-radius: 40px;
    border-radius: 2px;
    padding: 8px 16px;

    svg {
      color: white;
      opacity: 0.5;
    }

    input {
      color: rgba(white,0.5)
      
    }

    @media screen and (max-width: 1120px) {
      width: 0;
      padding: 0;
      svg {
        color: white;
        opacity: 0.5;
      }
      .search-icon {
        position: absolute;
        left: 205px;
        opacity: 1;
      }
    }
    @media screen and (max-width: 856px) {
      width: 100%;
      padding: 8px 16px;
      svg {
        color: white;
        opacity: 0.5;
      }
      .search-icon {
        position: relative;
        left: auto;
        opacity: 0.5;
      }
    }
    @media screen and (max-width: 500px) {
      width: 0;
      padding: 0;
      svg {
        color: white;
        opacity: 0.5;
      }
      .search-icon {
        position: absolute;
        right: 20px;
        opacity: 1;
      }
    }
    .form-control {
      width: 300px;
      background: transparent;
      border: none;
      outline: none;
      height: 30px;
      padding: 0 0.75rem;
      box-shadow: inset 0 0px 0 #ddd !important;
      @media screen and (max-width: 1300px) {
        width: 200px;
      }
      @media screen and (max-width: 1240px) {
        width: 100%;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0 0.25rem;
      }
      &:focus {
        background: transparent;
      }
    }
  }
  .navbar-toggler {
    border: none;
    color: white;
    margin-right: 20px;
    width: 20px;
    height: 14px;
    position: relative;
    flex-shrink: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    padding: 0;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      background: linear-gradient(90.26deg, #1AC4F0 10%, #F1459A 44.96%, #FFCA3D 72.16%), #C4C4C4;
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        top: 6px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        top: 6px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
    &.collapsed {
      span {
        background: white;
        &:nth-child(1) {
          top: 0px;
          width: 100%;
          left: 0;
        }
        &:nth-child(2) {
          top: 6px;
          -webkit-transform: none;
          -moz-transform: none;
          -o-transform: none;
          transform: none;
        }
        &:nth-child(3) {
          top: 6px;
          -webkit-transform: none;
          -moz-transform: none;
          -o-transform: none;
          transform: none;
        }
        &:nth-child(4) {
          top: 12px;
          width: 100%;
          left: 0;
        }
      }
    }
    @media screen and (max-width: 856px) {
      display: block;
    }
  }
  .dropdown-toggle {
    padding: 0 !important;
  }
  .dropdown-menu {
    min-width: 240px;
    background-color: white;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    border: 1px solid #eee;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.09);
    .dropdown-item {
      color: rgba(black, 0.5);
    }
  }
  #header-nav-dropdown {
    padding: 0px!important;
    height: 100%;
    display: flex;
    align-items: center;
    div.header-avatar {
      display: inline-block;
      background-color: rgb(255, 255, 255);
      width: 36px;
      height: 36px;
      max-width: 36px;
      max-height: 36px;
      position: relative;
      border-radius: 100%;
      margin-right: 5px;
      z-index: 1;
      img {
        width: 36px;
        height: 36px;
        object-fit: cover;
        border-radius: 36px;
      }
    }
    
  }
  .navbar-collapse {
    @media screen and (max-width: 856px) {
      display: none !important;
      opacity: 0;
      width: 0;
      transition: ease-in all .5s;
      &.show {
        height: calc(100vh - 89px);
        opacity: 1;
        transition: ease-in all .5s;
        background: linear-gradient(135.63deg, rgba(254, 176, 254, 0.1) -0.59%, rgba(34, 39, 39, 0.096) 59.4%, rgba(93, 247, 164, 0.1) 108.48%), rgba(20, 20, 20, 0.85);
        background-color: black;
        background-image: url('../assets/imgs/bg-marketplace-grid.svg');
        background-size: cover;
        background-position: center;
        display: flex !important;
        align-items: center;
        position: absolute;
        top: 74px;
        left: 0;
        width: 100%;
        padding: 10px 40px;
        z-index: 1000;
        .navbar-nav {
          flex-direction: column;
          margin-left: 0 !important;
          margin-bottom: 60px;
          align-items: start !important;
          .user-dropdown {
            position: absolute;
            top: 30px;
            right: 20px;
          }
        }
        .buttons {
          margin-top: 40px;
          flex-wrap: wrap;
          .btn {
            margin-left: 10px;
            margin-bottom: 20px;
          }
        }
      }
    }
    // @media screen and (max-width: 576px) {
    //   .buttons {
    //     a {
    //       width: 96%;
    //     }
    //     .btn {
    //       width: 100%;
    //     }
    //   }
    // }
  }
  .user-dropdown .dropdown-menu-right {
    @media screen and (max-width: 856px) {
      left: -200px;
    }
  }
  .coming-soon {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: rgba(0,0,0,0.05);
    color: #000;
    margin-left: 15px;
    font-size: 10px;
  }
  .quiz {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    color: rgba(black, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid rgba(black, 0.3);
  }
}

.search .header-search-form {
  @media screen and (max-width: 1120px) {
    width: calc(100% - 30px);
    padding: 8px 16px;
    position: fixed;
    top: 89px;
    z-index: 999;
    transition: all ease-in-out .5s;
    background: rgba(white, 0.05);
    
    .search-icon {
      left: auto;
      opacity: 0.5;
      position: relative;
      right: auto;
    }
  }
  @media screen and (max-width: 856px) {
    width: 100%;
    padding: 8px 16px;
    position: relative;
    top: auto;
    background: rgba(white, 0.05);
  }
  @media screen and (max-width: 500px) {
    width: calc(100% - 30px);
    padding: 8px 16px;
    position: fixed;
    top: 89px;
    z-index: 999;
    transition: all ease-in-out .5s;
    background: rgba(white, 0.05);
    .search-icon {
      left: auto;
      opacity: 0.5;
      position: relative;
      right: auto;
    }
  }
}
