.footer-container {
  padding: 40px 30px;
  background: #000;
  color: #fff;

  @media screen and (max-width: 554px) {
    padding: 40px 20px;
  }
  input.form-control {
    height: 50px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: white;
  }
  select.form-control {
    height: 50px;
    max-width: 300px;
    background-color: transparent;
    border-width: 2px;
    color: white;
    @media screen and (max-width: 554px) {
      max-width: 100%;
    }
    option {
      color: black;
    }
  }
  a {
    color: #fff !important;
    display: block;
    font-size: 12px;
  }
}
